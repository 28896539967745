import React, { FC, ReactElement, useCallback, useState } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import FontelloIcon from '@sport1/news-styleguide/FontelloIcon'
import { LabelProps, TagProps } from '@sport1/types/web'
import Touchable from '@sport1/news-styleguide/Touchable'
import Font from '@sport1/news-styleguide/Font'
import DropDownOverlay, { DropDownRenderItemProps } from '@/components/DropDownOverlay'
import TabbableArea from '@/components/TabbableArea'
import { slugify } from '@/helpers/slugHelper'
import { useMobile } from '@/hooks/useMobile'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import nofollowExternal from '@/utils/url/nofollowExternal'
import WrappedNavigationListItem from '@/components/WrappedNavigationListItem'
import { sport1Bold } from '@/helpers/fonts'

type OriginalsProps = {
    tags: (TagProps | LabelProps)[]
}

export const Originals: FC<OriginalsProps> = ({ tags }): ReactElement => {
    const { isMobile } = useMobile()
    const [showOptions, setShowOptions] = useState(false)
    const [activeBlur, setActiveBlur] = useState(true)
    const { trackInteraction } = useTracking()

    const onPress = useCallback(() => {
        setShowOptions(!showOptions)
        trackInteraction({
            devices: 'all',
            interaction_category: 'navigation',
            interaction_action: 'click',
            interaction_label: 'navigation_originals',
            platforms: isMobile ? 'mobile' : 'desktop',
        })
    }, [isMobile, showOptions, trackInteraction])

    const onNavigationItemPress = useCallback(
        (tag: TagProps | LabelProps) => {
            trackInteraction({
                devices: 'all',
                interaction_category: 'navigation',
                interaction_action: 'click',
                interaction_label: `originals_${slugify(tag.title)}`,
                platforms: isMobile ? 'mobile' : 'desktop',
            })
        },
        [isMobile, trackInteraction]
    )

    const renderItem = useCallback(
        ({ tag }: DropDownRenderItemProps): ReactElement => {
            const { title, iconUrl, href, hrefTarget } = tag
            // TODO: https://sport1digital.atlassian.net/browse/ML-6214
            return (
                <WrappedNavigationListItem
                    testID={`originals-navigation-item-${title}`}
                    key={title}
                    href={href || ''}
                    onPress={() => onNavigationItemPress(tag)}
                    title={title.toUpperCase()}
                    leadingType="image"
                    leading={iconUrl}
                    webRel={nofollowExternal}
                    webTarget={hrefTarget}
                />
            )
        },
        [onNavigationItemPress]
    )

    return (
        <TabbableArea
            testID="originals"
            style={{
                display: 'flex',
                position: 'relative',
                outline: 'none',
                alignItems: 'center',
            }}
            activateBlur={activeBlur}
            onBlur={() => setShowOptions(false)}
        >
            <Touchable onPress={onPress}>
                <NonFlexingContainer horizontal centerVertical>
                    <NonFlexingContainer width={55} centerHorizontal>
                        <FontelloIcon name="favorite" color="onyx" fontSize="FS20" />
                        <Font
                            color="onyx"
                            fontFamilyVariant="Sport1-Bold"
                            nextFontFamilyVariant={sport1Bold.style.fontFamily}
                            fontSize="11px"
                            lineHeight="lineHeight-16"
                        >
                            Originals
                        </Font>
                    </NonFlexingContainer>
                    <NonFlexingContainer
                        marginLeft="spacing-1"
                        marginRight="spacing-4"
                        marginTop="spacing-10"
                    ></NonFlexingContainer>
                </NonFlexingContainer>
            </Touchable>
            {showOptions && (
                <DropDownOverlay
                    top="100%"
                    tags={tags}
                    maxHeight={340}
                    renderItem={renderItem}
                    onMouseOver={(): void => setActiveBlur(false)}
                    onMouseLeave={(): void => setActiveBlur(true)}
                    backgroundColor="pearl"
                />
            )}
        </TabbableArea>
    )
}
