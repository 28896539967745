import React, { FC, useState, useCallback, useMemo } from 'react'
import NextSearchBar from '@sport1/news-styleguide/NextSearchBar'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Font from '@sport1/news-styleguide/Font'
import { TagProps, LabelProps } from '@sport1/types/web'
import DropDownOverlay, { DropDownRenderItemProps } from '@/components/DropDownOverlay'
import { slugify } from '@/helpers/slugHelper'
import { useMobile } from '@/hooks/useMobile'
import { SearchBarProps } from '@/layouts/Standard/Header/components/HeaderBanner/components/Search'
import { getLayout } from '@/pages/api/cms/content/tag/[id]'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import nofollowExternal from '@/utils/url/nofollowExternal'
import WrappedNavigationListItem from '@/components/WrappedNavigationListItem'
import { sport1Regular } from '@/helpers/fonts'

const SearchContainer: FC<SearchBarProps> = ({ placeholder, searchUrl, popularUrl }) => {
    const { isMobile } = useMobile()
    const [showOptions, setShowOptions] = useState(false)
    const [activeBlur, setActiveBlur] = useState(true)
    const [tags, setTags] = useState<(TagProps | LabelProps)[]>([])
    const [searchString, setSearchString] = useState('')
    const { trackInteraction } = useTracking()

    const finalQuery = useMemo(
        () => (searchString ? searchUrl?.replace('{query}', searchString) : popularUrl),
        [searchString, searchUrl, popularUrl]
    )

    React.useEffect(() => {
        if (finalQuery && showOptions) {
            getLayout(finalQuery).then(layout => {
                const component = layout?.components && layout.components[0]
                if (component && 'tags' in component) {
                    setTags(component.tags as (TagProps | LabelProps)[])
                }
            })
        }
    }, [finalQuery, showOptions])

    const onChange = useCallback((text: string): void => {
        setSearchString(text)
    }, [])

    const onPress = useCallback(() => {
        setShowOptions(!showOptions)
        trackInteraction({
            devices: 'all',
            interaction_category: 'navigation',
            interaction_action: 'click',
            interaction_label: 'searchbar',
            platforms: isMobile ? 'mobile' : 'desktop',
        })
    }, [isMobile, showOptions, trackInteraction])

    const onNavigationItemPress = useCallback(
        (tag: TagProps | LabelProps) => {
            if (finalQuery === popularUrl) {
                trackInteraction({
                    devices: 'all',
                    interaction_category: 'navigation',
                    interaction_action: 'click',
                    interaction_label: `searchbar_mostsearched_${slugify(tag.title)}`,
                    platforms: isMobile ? 'mobile' : 'desktop',
                })
            }
        },
        [finalQuery, isMobile, popularUrl, trackInteraction]
    )

    const renderItem = useCallback(
        ({ tag, href }: DropDownRenderItemProps) => (
            <WrappedNavigationListItem
                testID="search-navigation-list-item"
                key={tag.id}
                href={href || ''}
                onPress={() => onNavigationItemPress(tag)}
                title={tag.title}
                leadingType="image"
                leading={tag.iconUrl}
                categoryType={tag.categoryType}
                trailingIcon="scroll-right"
                trailingIconColor="onyx"
                webRel={nofollowExternal}
            />
        ),
        [onNavigationItemPress]
    )

    return (
        <div
            style={{
                flex: 0,
                position: 'relative',
                outline: 'none',
            }}
            tabIndex={0}
            onBlur={activeBlur ? (): void => setShowOptions(false) : undefined}
            onClick={onPress}
        >
            <NextSearchBar testID="next-search-bar" placeholder={placeholder} onChange={onChange} />
            {showOptions && (
                <DropDownOverlay
                    testID="searchbar-drop-down-overlay"
                    tags={tags}
                    top="110%"
                    width="100%"
                    maxHeight={350}
                    header={
                        finalQuery === popularUrl ? (
                            <NonFlexingContainer
                                testID="popular-search-results-wrapper"
                                key="popular-search-results-wrapper"
                                paddingX="spacing-6"
                                paddingTop="spacing-6"
                                paddingBottom="spacing-2"
                                backgroundColor="pearl"
                            >
                                <Font
                                    fontVariant="Label-L"
                                    fontFamilyVariant="Sport1-Regular"
                                    nextFontFamilyVariant={sport1Regular.style.fontFamily}
                                    color="keshi-3"
                                >
                                    Häufige Suchanfragen
                                </Font>
                            </NonFlexingContainer>
                        ) : undefined
                    }
                    renderItem={renderItem}
                    onMouseOver={(): void => setActiveBlur(false)}
                    onMouseLeave={(): void => setActiveBlur(true)}
                    backgroundColor="pearl"
                />
            )}
        </div>
    )
}

export default SearchContainer
